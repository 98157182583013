var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-link',{staticClass:"btn btn-sm btn-outline-primary",attrs:{"to":{
            name: 'ProjectViewCosts',
            params: { project_id: _vm.projectId }
        }}},[_vm._v(_vm._s(_vm.$t("vouchers.view_booked_costs")))]),_c('router-link',{staticClass:"btn btn-outline-primary btn-sm",attrs:{"to":{
            name: 'ProjectViewCostsInbox',
            params: { project_id: _vm.projectId }
        }}},[_vm._v(_vm._s(_vm.$t("vouchers.view_cost_inbox")))]),_c('router-link',{staticClass:"btn btn-outline-primary btn-sm",attrs:{"to":{
            name: 'ProjectViewCostsReport',
            params: { project_id: _vm.projectId }
        }}},[_vm._v(_vm._s(_vm.$t("vouchers.view_milestone_report")))]),_c('router-link',{staticClass:"btn btn-outline-primary btn-sm",attrs:{"to":{
            name: 'ProjectViewCostsBudgetReport',
            params: { project_id: _vm.projectId }
        }}},[_vm._v(_vm._s(_vm.$t("vouchers.view_acc_report")))]),_c('router-link',{staticClass:"btn btn-outline-primary btn-sm",attrs:{"to":{
            name: 'ProjectViewCostsDetailedBudgetReport',
            params: { project_id: _vm.projectId }
        }}},[_vm._v(_vm._s(_vm.$t("vouchers.view_detailed_report")))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }