<script>
import {
    FlagStatus,
    FlagTypes,
    FlagTypesDetailed,
    FlagTypesIcons,
    FlagSubtypes
} from "@/constants/Planning.const";

export default {
    props: {
        flag: {}
    },
    computed: {
        flagSubtypes() {
            return FlagSubtypes;
        }
    }
};
</script>

<template>
    <span>
        <template v-if="flag.subtype === 'flag'">
            {{ flag.flag_category.name }}
        </template>
        <template v-else>
            {{ flagSubtypes[flag.subtype] }}
        </template>
    </span>
</template>
