<script>
export default {
    props: {
        diffData: {}
    }
};
</script>

<template>
    <div>
        <div v-if="parseInt(diffData.cod_delay_weeks) > 0">
            {{ $tc("flag_report.COUNT_weeks", diffData.cod_delay_weeks) }}
        </div>

        <div class="mt-2">
            <div>{{ $t("flag_report.new_cod") }}:</div>
            {{ diffData.new_cod_week }} {{ diffData.new_cod_week_year }}
        </div>
    </div>
</template>
