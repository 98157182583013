var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-project-report-group"},[_c('card',[_c('h2',{staticClass:"mb-3"},[_c('i',{staticClass:"fas fa-flag",class:("flag-" + _vm.alarm)}),_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.flags.length > 0)?[_c('v-table',{staticClass:"table table-sm table-bordered table__flag"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_vm._v(_vm._s(_vm.$t("flag_report.hdr_project")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("flag_report.hdr_milestone")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("flag_report.hdr_fsubtype")))]),(_vm.normal)?[_c('v-th',[_vm._v(_vm._s(_vm.$t("flag_report.hdr_impact")))])]:[_c('v-th',[_vm._v(_vm._s(_vm.$t("flag_report.hdr_timeline_change")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("flag_report.hdr_cod_delay")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("flag_report.hdr_impact")))]),_c('v-th',[_vm._v(_vm._s(_vm.$t("flag_report.hdr_mitigation")))])]],2)],1),_c('v-tbody',_vm._l((_vm.flags),function(f,idx){return _c('v-tr',{key:idx},[(_vm.normal)?[_c('v-td',{staticClass:"table__flag_title"},[_c('router-link',{attrs:{"to":{
                                        name: 'ProjectDisplay',
                                        params: { project_id: f.id }
                                    }}},[_vm._v(_vm._s(f.title))])],1),_c('v-td',{staticClass:"text-center table__flag_milestone"},[(
                                        Array.isArray(
                                            f.project_milestones
                                        ) && f.project_milestones.length > 0
                                    )?_c('v-status-milestone',{attrs:{"milestone":f.project_milestones[0]}}):[_vm._v("---")]],2),_c('v-td',{staticClass:"table__flag_subtype"},[_vm._v(" "+_vm._s(_vm.$t("flag_report.milestone"))+" "+_vm._s(f.pm_data.m_ident)+" "),_c('div',{staticClass:"small text-muted"},[_vm._v(" "+_vm._s(_vm.$t("flag_report.created"))+": "+_vm._s(_vm.$d( new Date( f.pm_data.done_initiated_date ), "short" ))+" ")]),_c('div',{staticClass:"small v-flag-meta text-muted"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("flag.status"))+": "+_vm._s(f.pm_data.status_name)+" ")]),(f.pm_data.done_confirmed_date)?_c('div',[_c('i',{staticClass:"far fa-calendar"}),_vm._v(" "+_vm._s(_vm.$d( new Date( f.pm_data.done_confirmed_date ), "short" ))+" ")]):_vm._e()])]),_c('v-td',{staticClass:"table__flag_impact2"},[_c('v-newlines',{attrs:{"text":f.pm_data.done_note}})],1)]:[_c('v-td',{staticClass:"table__flag_title"},[_c('router-link',{attrs:{"to":{
                                        name: 'ProjectDisplay',
                                        params: { project_id: f.project.id }
                                    }}},[_vm._v(_vm._s(f.project.title))])],1),_c('v-td',{staticClass:"text-center table__flag_milestone"},[(
                                        Array.isArray(
                                            f.project.project_milestones
                                        ) &&
                                            f.project.project_milestones
                                                .length > 0
                                    )?_c('v-status-milestone',{attrs:{"milestone":f.project.project_milestones[0]}}):[_vm._v("---")]],2),_c('v-td',{staticClass:"table__flag_subtype"},[_c('v-flag-name',{attrs:{"flag":f}}),(f.subtype == 'timeline_change')?_c('div',{staticClass:"small"},[_c('span',{staticClass:"m-click text-primary",on:{"click":function($event){return _vm.showTimeline(f.project_id)}}},[_c('i',{staticClass:"far fa-search"}),_vm._v(" "+_vm._s(_vm.$t("flag_report.show_details")))])]):_vm._e(),_c('div',{staticClass:"small text-muted mt-2"},[_vm._v(" "+_vm._s(_vm.$t("flag_report.created"))+": "+_vm._s(_vm.$d(new Date(f.created), "short"))+" ")]),_c('v-flag-meta',{attrs:{"flag":f}})],1),_c('v-td',{staticClass:"table__flag_timeline_change"},[(f.diff_data)?[_c('v-timeline-diff',{attrs:{"diff":f.diff_data}})]:[_vm._v("---")]],2),_c('v-td',{staticClass:"table__flag_cod"},[(
                                        f.diff_data &&
                                            f.diff_data.new_cod_week
                                    )?[_c('v-cod',{attrs:{"diff-data":f.diff_data}})]:[_vm._v("---")]],2),_c('v-td',{staticClass:"table__flag_impact"},[_c('v-newlines',{attrs:{"text":f.description}})],1),_c('v-td',{staticClass:"table__flag_how"},[_c('v-newlines',{attrs:{"text":f.description2}})],1)]],2)}),1)],1)]:_c('div',{staticClass:"alert alert-outline alert-warning"},[_vm._v(" "+_vm._s(_vm.$t("project.group_no_projects_in_this_group"))+" ")])],2),_c('portal',{attrs:{"to":"modals"}},[(_vm.showEditTimeline)?_c('v-timeline-edit-form',{attrs:{"project-id":_vm.timelineContext,"project-flag-id":_vm.timelineFlagContext,"force-ro":true},on:{"close":function($event){_vm.showEditTimeline = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }