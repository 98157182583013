<script>
/**
 * Management of project milestones in a project (LOCAL CONTEXT)
 */
import ProjectMilestoneService from "@/services/ProjectMilestoneService";
import ProjectService from "@/services/ProjectService";
import Cloner from "@/libraries/Cloner";
import VoucherService from "@/services/VoucherService";
import VVoucherDetailsModal from "@/components/Costs/VVoucherDetailsModal";
import VAllocateModal from "@/components/Costs/VAllocateModal";
import VPdfModal from "@/components/Costs/VPdfModal";
import Messages from "@/mixins/Messages";
import VFilters from "@/components/Search/VFilters";
import VEcConnection from "@/components/Costs/VEcConnection";
import { VoucherStatus } from "@/constants/Voucher.const";
import CostSubmenu from "./components/CostSubmenu";

export default {
    name: "",
    props: {
        project_id: {}
    },
    mixins: [Messages],
    components: {
        VVoucherDetailsModal,
        VAllocateModal,
        VPdfModal,
        VFilters,
        VEcConnection,
        CostSubmenu
    },
    data() {
        return {
            loading: true,
            vouchers: [],
            meta: {},
            showVoucherDetailsModal: false,
            voucherContext: null,
            showAllocateModal: false,
            allocateContext: null,
            project: {},
            showDocModal: false,
            docContext: null
        };
    },
    computed: {
        voucherStatuses() {
            let v = VoucherStatus;
            delete v[10];
            return v;
        }
    },
    async created() {
        this.initSearch();
        await this.fetchProject();
        await this.fetchData();
        this.loading = false;
        this.$apiEnableWatcher();
    },
    methods: {
        async fetchData() {
            try {
                let params = this.$buildParams();
                params["project_id"] = this.project_id;
                params["functionView"] = "inbox";
                const { data } = await VoucherService.GET(params);
                this.vouchers = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fd e", e);
            }
        },
        /**
         * Ignore a given voucher
         * @param voucher_id
         */
        async ignoreVoucher(voucher_id) {
            this.$swal({
                icon: "warning",
                text: this.$t("vouchers.swal_confirm_ignore_voucher"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    await VoucherService.PUT_ignore(voucher_id);
                    this.fetchData();
                    this.$msgSuccess(
                        this.$t("vouchers.notify_voucher_ignored")
                    );
                }
            });
        },
        /**
         * Un-ignore a voucher
         * @param voucher_id
         */
        async unIgnoreVoucher(voucher_id) {
            this.$swal({
                icon: "warning",
                text: this.$t("vouchers.swal_confirm_unignore_voucher"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    await VoucherService.PUT_unignore(voucher_id);
                    this.fetchData();
                    this.$msgSuccess(
                        this.$t("vouchers.notify_voucher_unignored")
                    );
                }
            });
        },
        /**
         * Fetch the project + customer
         */
        async fetchProject() {
            try {
                const r = await ProjectService.GETS(this.project_id);
                this.project = r.data.data;
            } catch (e) {
                console.log("fetchProject exception", e);
            }
        },
        showVoucherDetails(ctx) {
            this.voucherContext = ctx;
            this.showVoucherDetailsModal = true;
        },
        /**
         * Close allocate & reload
         */
        closeReload() {
            this.showAllocateModal = false;
            this.fetchData();
            this.$notify({
                message: this.$t("vouchers.notify_new_entry_created"),
                timeout: 5000,
                icon: "far fa-check",
                type: "success"
            });
        },
        showAllocate(ctx) {
            this.allocateContext = ctx;
            this.showAllocateModal = true;
        },
        showVoucherDoc(v) {
            this.showDocModal = true;
            this.docContext = v;
        },
        initSearch() {
            this.apiSearchFilter = {
                search: "",
                status: "1",
                ec_voucher_number: ""
            };
        }
    }
};
</script>

<template>
    <div class="container-fluid">
        <div class="row card-wrapper">
            <div class="col-12">
                <v-filters
                    :enable-filters="true"
                    v-model="apiSearchFilter.search"
                    :search="true"
                    start-open
                    :search-placeholder="
                        $t('vouchers.placeholder_search_in_vouchers')
                    "
                    @reset-search="initSearch"
                >
                    <template slot="default">
                        <div class="col-lg-3 col-md-12">
                            <FormulateInput
                                v-model="apiSearchFilter.status"
                                :placeholder="$t('form_elems.voucher_status')"
                                type="multiselect"
                                :multiple="false"
                                :options="voucherStatuses"
                            />
                        </div>
                        <div class="col-lg-3 col-md-12">
                            <FormulateInput
                                v-model="apiSearchFilter.ec_voucher_number"
                                type="text"
                                :placeholder="
                                    $t('form_elems.ec_voucher_number')
                                "
                            />
                        </div>
                    </template>
                </v-filters>
                <card body-classes="px-0">
                    <div
                        class="d-flex align-items-center mb-4 justify-content-between mx-4"
                    >
                        <h2 class="">{{ $t("vouchers.voucher_inbox") }}</h2>
                       <cost-submenu :project-id="project_id" me="costs-inbox" />
                    </div>

                    <v-loader :loading="loading" v-if="loading" />
                    <template v-else-if="vouchers.length > 0">
                        <v-table class="table table-mg table-sm">
                            <v-thead>
                                <v-tr>
                                    <v-th
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            @sort="$handleSorting($event)"
                                            name="created"
                                            >{{
                                                $t("vouchers.tbl_hdr_imported")
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            @sort="$handleSorting($event)"
                                            name="ec_voucher_number"
                                            >{{
                                                $t(
                                                    "vouchers.tbl_hdr_voucher_nr"
                                                )
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            @sort="$handleSorting($event)"
                                            name="voucher_date"
                                            >{{
                                                $t("vouchers.tbl_hdr_date")
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            @sort="$handleSorting($event)"
                                            name="description"
                                            >{{
                                                $t(
                                                    "vouchers.tbl_hdr_description"
                                                )
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th class="text-right"
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            @sort="$handleSorting($event)"
                                            name="amount"
                                            >{{
                                                $t("vouchers.tbl_hdr_amount")
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th class="text-right"
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            @sort="$handleSorting($event)"
                                            name="orig_amount"
                                            >{{
                                                $t(
                                                    "vouchers.tbl_hdr_orig_amount"
                                                )
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            @sort="$handleSorting($event)"
                                            name="ec_connection_id"
                                            >{{
                                                $t(
                                                    "vouchers.tbl_hdr_ec_connection"
                                                )
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th></v-th>
                                </v-tr>
                            </v-thead>
                            <v-tbody>
                                <v-tr v-for="v in vouchers" :key="v.id">
                                    <v-td
                                        >{{ $d(new Date(v.created), "short") }}
                                        <div
                                            class="small text-muted"
                                            v-if="false"
                                        >
                                            {{ v.id }}
                                        </div></v-td
                                    >
                                    <v-td>{{ v.ec_voucher_number }}</v-td>
                                    <v-td>{{
                                        $d(new Date(v.voucher_date), "short")
                                    }}</v-td>
                                    <v-td
                                        >{{ v.description }}
                                        <i
                                            @click="
                                                showVoucherDetails(v.raw_data)
                                            "
                                            class="far m-click text-muted fa-info-square fa-fw fa-lg"
                                        ></i
                                    ></v-td>
                                    <v-td class="text-right"
                                        >{{
                                            $n(v.amount, {
                                                style: "currency",
                                                currency: v.currency,
                                                currencyDisplay: "code"
                                            })
                                        }}
                                        <div
                                            class="text-danger"
                                            v-if="v.amount < 0"
                                        >
                                            <i
                                                class="far fa-exclamation-triangle"
                                            ></i>
                                            {{
                                                $t(
                                                    "vouchers.note_neg_amount_can_only_ignore"
                                                )
                                            }}
                                        </div>
                                    </v-td>
                                    <v-td class="text-right">{{
                                        $n(v.orig_amount, {
                                            style: "currency",
                                            currency: v.orig_currency,
                                            currencyDisplay: "code"
                                        })
                                    }}</v-td>

                                    <v-td>
                                        <v-ec-connection :context="v" />
                                    </v-td>
                                    <v-td style="width: 180px">
                                        <base-button
                                            v-if="v.status == 1"
                                            size="sm"
                                            type="primary"
                                            :disabled="v.amount < 0"
                                            @click="showAllocate(v)"
                                            block
                                        >
                                            <i
                                                class="far fa-book-open fa-fw mr-1"
                                            ></i>
                                            {{ $t("vouchers.action_allocate") }}
                                        </base-button>
                                        <base-button
                                            size="sm"
                                            type="info"
                                            outline
                                            @click="showVoucherDoc(v)"
                                            :disabled="!v.has_doc"
                                            block
                                        >
                                            <i
                                                class="far fa-file-pdf fa-fw mr-1"
                                            ></i>
                                            {{ $t("vouchers.action_show_pdf") }}
                                        </base-button>
                                        <base-button
                                            v-if="v.status == 1"
                                            size="sm"
                                            type="warning"
                                            outline
                                            block
                                            @click="ignoreVoucher(v.id)"
                                        >
                                            <i
                                                class="far fa-times-square fa-fw mr-1"
                                            ></i>
                                            {{
                                                $t("vouchers.action_ignore")
                                            }}</base-button
                                        >
                                        <base-button
                                            v-else
                                            size="sm"
                                            type="success"
                                            outline
                                            block
                                            @click="unIgnoreVoucher(v.id)"
                                        >
                                            <i
                                                class="far fa-undo fa-fw mr-1"
                                            ></i>
                                            {{
                                                $t("vouchers.action_unignore")
                                            }}</base-button
                                        >
                                    </v-td>
                                </v-tr>
                            </v-tbody>
                        </v-table>
                        <div class="paginator">
                            <base-pagination
                                :page-count="meta.pagination.total_pages"
                                :per-page="meta.pagination.per_page"
                                :value="meta.pagination.current_page"
                                @input="$handlePagination($event)"
                            />
                        </div>
                    </template>
                    <div class="alert alert-warning mx-4" v-else>
                        {{ $t("vouchers.no_vouchers_found") }}
                    </div>
                </card>
            </div>
        </div>
        <portal to="modals">
            <v-allocate-modal
                :context="allocateContext"
                :project-id="project_id"
                :project="project"
                v-if="showAllocateModal"
                @close="showAllocateModal = false"
                @close-reload="closeReload"
            />
            <v-voucher-details-modal
                v-if="showVoucherDetailsModal"
                @close="showVoucherDetailsModal = false"
                :context="voucherContext"
            />
            <v-pdf-modal
                v-if="showDocModal"
                :context="docContext"
                @close="showDocModal = false"
            />
        </portal>
    </div>
</template>

<style lang="scss"></style>
