<script>
import VStatusMilestone from "@/components/Planning/Helpers/VStatusMilestone";
import VFlagName from "@/components/Planning/VFlagName";
import {
    FlagStatus,
    FlagTypes,
    FlagTypesDetailed,
    FlagTypesIcons,
    FlagSubtypes
} from "@/constants/Planning.const";
import VListFlags from "./VListFlags";

export default {
    props: {
        projects: {},
        paginator: {},
        noStage: {
            type: Boolean,
            default: false
        }
    },
    components: { VListFlags, VStatusMilestone, VFlagName },
    computed: {
        listFlags() {
            return FlagTypes;
        },
        flagTypes() {
            return this.$hash2select(FlagTypes);
        },
        flagDetails() {
            return FlagTypesDetailed;
        },
        flagIcons() {
            return FlagTypesIcons;
        },
        flagStatus() {
            return FlagStatus;
        },
        flagSubtypes() {
            return FlagSubtypes;
        }
    }
};
</script>

<template>
    <div class="v-portfolio-stage-table">
        <div
            v-if="
                typeof projects === 'undefined' ||
                    (Array.isArray(projects) && projects.length == 0)
            "
        >
            <div class="v-portfolio-stage-table__empty_stage mx-4">
                {{ $t("project.no_projects_in_this_stage") }}
            </div>
        </div>
        <v-table class="table table-mg table-sm table-bordered" v-else>
            <v-thead>
                <v-tr>
                    <v-th class="v-portfolio-stage-table__title"
                        ><v-sorter
                            @sort="$emit('sort', 'Projects.title')"
                            name="Projects.title"
                            :paginator="paginator"
                            >{{ $t("project.title") }}</v-sorter
                        ></v-th
                    >
                    <v-th class="v-portfolio-stage-table__type"
                        ><v-sorter
                            @sort="$emit('sort', 'Projects.project_type')"
                            name="Projects.project_type"
                            :paginator="paginator"
                            >{{ $t("project.type") }}</v-sorter
                        ></v-th
                    >
                    <v-th class="v-portfolio-stage-table__status">
                        <v-sorter
                            @sort="$emit('sort', 'Projects.status')"
                            name="Projects.status"
                            :paginator="paginator"
                            >{{ $t("project.status") }}
                        </v-sorter></v-th
                    >
                    <v-th class="v-portfolio-stage-table__pm">
                        <v-sorter
                            @sort="$emit('sort', 'ResponsibleUsers.name')"
                            name="ResponsibleUsers.name"
                            :paginator="paginator"
                            >{{ $t("project.project_manager") }}</v-sorter
                        >
                    </v-th>
                    <v-th
                        class="v-portfolio-stage-table__milestone text-center"
                    >
                        <v-sorter
                            @sort="$emit('sort', 'ProjectMilestones.m_ident')"
                            name="ProjectMilestones.m_ident"
                            :paginator="paginator"
                            >{{ $t("project.milestone") }}</v-sorter
                        ></v-th
                    >
                    <v-th class="v-portfolio-stage-table__municipality"
                        ><v-sorter
                            @sort="$emit('sort', 'Municipalities.name')"
                            name="Municipalities.name"
                            :paginator="paginator"
                            >{{ $t("project.municipality") }}</v-sorter
                        ></v-th
                    >
                    <v-th class="v-portfolio-stage-table__flags">
                        {{ $t("project.flags") }}
                    </v-th>
                </v-tr>
            </v-thead>
            <v-tbody>
                <v-tr v-for="p in projects" :key="p.id">
                    <template v-if="true">
                        <v-td>
                            <router-link
                                :to="{
                                    name: 'ProjectDisplay',
                                    params: { project_id: p.project.id }
                                }"
                                >{{ p.project.title }}</router-link
                            >

                            <pre v-if="false">{{
                                p.project.project_flags
                            }}</pre>
                        </v-td>
                        <v-td>{{ p.project.project_type_name }}</v-td>
                        <v-td>{{ p.project.status_name }}</v-td>
                        <v-td>{{ p.project.responsible_user.name }}</v-td>
                        <v-td class="text-center">
                            <template v-if="noStage">---</template>
                            <template v-else-if="'id' in p.project_milestone">
                                <v-status-milestone
                                    :milestone="p.project_milestone"
                                />
                            </template>
                            <template v-else>---</template>
                        </v-td>
                        <v-td
                            ><template v-if="'municipality' in p.project">{{
                                p.project.municipality.name
                            }}</template>
                            <template v-else>---</template></v-td
                        >
                        <v-td>
                            <template v-if="'project_flags' in p.project">
                                <ul class="list-unstyled mb-0">
                                    <li
                                        v-for="(flag, fidx) in p.project
                                            .project_flags"
                                        :key="fidx"
                                    >
                                        <i
                                            :class="flagIcons[flag.flag_type]"
                                        ></i>
                                        <v-flag-name :flag="flag" />
                                    </li>
                                </ul>
                            </template>
                            <template v-else>---</template>
                        </v-td>
                    </template>
                </v-tr>
            </v-tbody>
        </v-table>
    </div>
</template>

<style lang="scss">
.v-portfolio-stage-table {
    &__empty_stage {
        border: 1px solid $gray-200;
        border-radius: 6px;
        padding: 10px;
        font-size: 11px;
        color: $gray-600;
    }

    &__title {
    }

    &__type {
        width: 100px;
    }
    &__status {
        width: 120px;
    }
    &__pm {
        width: 190px;
    }
    &__milestone {
        width: 120px;
    }
    &__municipality {
        width: 180px;
        overflow: hidden;
    }
    &__flags {
        width: 200px;
    }
}
</style>
