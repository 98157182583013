<script>
/**
 * costs
 * costs-inbox
 * stats
 * stats-budget
 */
export default {
    props: {
        projectId: {},
        me: {
            type: String
        }
    }
};
</script>

<template>
    <div>
        <router-link
            class="btn btn-sm btn-outline-primary"
            :to="{
                name: 'ProjectViewCosts',
                params: { project_id: projectId }
            }"
            >{{ $t("vouchers.view_booked_costs") }}</router-link
        >

        <router-link
            class="btn btn-outline-primary btn-sm"
            :to="{
                name: 'ProjectViewCostsInbox',
                params: { project_id: projectId }
            }"
            >{{ $t("vouchers.view_cost_inbox") }}</router-link
        >

        <router-link
            class="btn btn-outline-primary btn-sm"
            :to="{
                name: 'ProjectViewCostsReport',
                params: { project_id: projectId }
            }"
            >{{ $t("vouchers.view_milestone_report") }}</router-link
        >

        <router-link
            class="btn btn-outline-primary btn-sm"
            :to="{
                name: 'ProjectViewCostsBudgetReport',
                params: { project_id: projectId }
            }"
            >{{ $t("vouchers.view_acc_report") }}</router-link
        >

        <router-link
            class="btn btn-outline-primary btn-sm"
            :to="{
                name: 'ProjectViewCostsDetailedBudgetReport',
                params: { project_id: projectId }
            }"
            >{{ $t("vouchers.view_detailed_report") }}</router-link
        >


    </div>
</template>
