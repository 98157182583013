<script>
import MilestoneAllocationService from "@/services/MilestoneAllocationService";
import CostSubmenu from "./components/CostSubmenu";
import { mapGetters } from "vuex";

export default {
    props: {
        project_id: {}
    },
    components: { CostSubmenu },
    data() {
        return {
            loading: true,
            data: [],
            hashed: {},
            meta: {},
            nodata: false,
            triggers: {},
            BK: false,
        };
    },
    computed: {
        ...mapGetters("auth", ["getAclMap"])
    },
    async created() {
        this.BK = this.$aclCan(this.$ACL.CUSTOM_PLANNING_BK);
        this.loading = true;
        await this.fetchData();
        this.data.forEach(e => {
            this.$set(this.triggers, e.m_ident, false);
        });
        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                const { data } = await MilestoneAllocationService.GET_report(
                    this.project_id
                );
                this.data = data.data;
                this.nodata = false;
            } catch (e) {
                console.log("f.e.", e);
                this.nodata = true;
            }
        },
        trigger(m_ident) {
            this.triggers[m_ident] = !this.triggers[m_ident];
        }
    }
};
</script>

<template>
    <div>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card>
                        <div
                            class="d-flex align-items-center mb-0 justify-content-between"
                        >
                            <h2 class="">
                                {{ $t("vouchers.cost_report") }}
                            </h2>
                            <cost-submenu :project-id="project_id" me="costs" />
                        </div>
                    </card>
                    <v-loader :loading="loading" v-if="loading" />
                    <template v-else-if="!nodata">
                        <card v-for="(m, idx) in data">
                            <div class="row">
                                <div class="col">
                                    <div class="d-flex justify-content-between">
                                        <h4 class="pb-1">
                                            M{{ m.m_ident }}
                                            {{ m.milestone_name }}
                                            <base-button
                                                @click="trigger(m.m_ident)"
                                                size="sm"
                                                type="primary"
                                                outline
                                                ><i class="far fa-plus"></i
                                            ></base-button>
                                        </h4>
                                        <div class="text-bold text-default">
                                            {{
                                                $n(m.milestone_amount_cost, {
                                                    style: "currency",
                                                    currency: m.currency,
                                                    currencyDisplay: "code"
                                                })
                                            }}
                                        </div>
                                    </div>
                                    <template v-if="triggers[m.m_ident]">
                                        <hr class="my-3" />
                                        <div class="row">
                                            <div class="col" v-if="BK">
                                                <div>
                                                    <dl>
                                                        <dt>
                                                            {{
                                                                $t(
                                                                    "vouchers.total_allocated"
                                                                )
                                                            }}
                                                        </dt>
                                                        <dd>
                                                            {{
                                                                $n(
                                                                    m.milestone_amount,
                                                                    {
                                                                        style:
                                                                            "currency",
                                                                        currency:
                                                                            m.currency,
                                                                        currencyDisplay:
                                                                            "code"
                                                                    }
                                                                )
                                                            }}
                                                        </dd>

                                                        <dt>
                                                            {{
                                                                $t(
                                                                    "vouchers.total_cost"
                                                                )
                                                            }}
                                                        </dt>
                                                        <dd>
                                                            {{
                                                                $n(
                                                                    m.milestone_amount_cost,
                                                                    {
                                                                        style:
                                                                            "currency",
                                                                        currency:
                                                                            m.currency,
                                                                        currencyDisplay:
                                                                            "code"
                                                                    }
                                                                )
                                                            }}
                                                        </dd>

                                                        <dt
                                                            class="text-warning"
                                                        >
                                                            {{
                                                                $t(
                                                                    "vouchers.total_noncost"
                                                                )
                                                            }}
                                                        </dt>
                                                        <dd
                                                            class="text-warning"
                                                        >
                                                            {{
                                                                $n(
                                                                    m.milestone_amount_noncost,
                                                                    {
                                                                        style:
                                                                            "currency",
                                                                        currency:
                                                                            m.currency,
                                                                        currencyDisplay:
                                                                            "code"
                                                                    }
                                                                )
                                                            }}
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <v-table
                                                    class="table table-sm table-mg table-voucher-report"
                                                >
                                                    <v-thead>
                                                        <v-tr>
                                                            <v-th>{{
                                                                $t(
                                                                    "vouchers.tbl_hdr_category"
                                                                )
                                                            }}</v-th>
                                                            <v-th
                                                                class="text-right"
                                                                >{{
                                                                    $t(
                                                                        "vouchers.tbl_hdr_amount"
                                                                    )
                                                                }}</v-th
                                                            >
                                                        </v-tr>
                                                    </v-thead>
                                                    <v-tbody>
                                                        <v-tr
                                                            v-for="entr in m.voucher_categories"
                                                            :class="{'d-none': !BK && entr.category_function == 2}"
                                                        >
                                                            <v-td
                                                                :class="{
                                                                    'text-warning text-bold':
                                                                        entr.category_function ===
                                                                        2
                                                                }"
                                                                >{{
                                                                    entr.category_name
                                                                }}</v-td
                                                            >
                                                            <v-td
                                                                class="text-right"
                                                                :class="{
                                                                    'text-warning text-bold':
                                                                        entr.category_function ===
                                                                        2
                                                                }"
                                                                >{{
                                                                    $n(
                                                                        entr.amount_category,
                                                                        {
                                                                            style:
                                                                                "currency",
                                                                            currency:
                                                                                entr.currency,
                                                                            currencyDisplay:
                                                                                "code"
                                                                        }
                                                                    )
                                                                }}
                                                            </v-td>
                                                        </v-tr>
                                                        <v-tr v-if="false">
                                                            <v-td>SAMLET</v-td>
                                                            <v-td>{{ m }}</v-td>
                                                            <v-td>
                                                                {{
                                                                    hashed[
                                                                        p
                                                                            .project_id
                                                                    ]
                                                                }}
                                                            </v-td>
                                                        </v-tr>
                                                    </v-tbody>
                                                </v-table>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </card>

                        <template v-if="false">
                            <card
                                body-classes=""
                                v-for="(p, project_id) in meta.projects"
                                :key="project_id"
                            >
                                <div
                                    class="d-flex align-items-center mb-4 justify-content-between"
                                >
                                    <h2 class="">
                                        {{ $t("vouchers.cost_report") }}
                                    </h2>
                                    <cost-submenu
                                        :project-id="project_id"
                                        me="costs"
                                    />
                                </div>
                                <hr />

                                <div
                                    v-for="(m, k) in meta.milestones[
                                        project_id
                                    ]"
                                >
                                    <div class="row">
                                        <div class="col">
                                            <h4>
                                                M{{ m.m_ident }}
                                                {{ m.milestone_name }}
                                            </h4>
                                            <div>
                                                {{
                                                    $t(
                                                        "vouchers.total_in_this_milestone"
                                                    )
                                                }}: {{ m.amount_milestone }}
                                                {{ m.currency }}
                                            </div>
                                        </div>
                                        <div class="col">
                                            <v-table
                                            
                                                class="table table-sm table-mg table-voucher-report"
                                            >
                                                <v-thead>
                                                    <v-tr>
                                                        <v-th>{{
                                                            $t(
                                                                "vouchers.tbl_hdr_category"
                                                            )
                                                        }}</v-th>
                                                        <v-th
                                                            class="text-right"
                                                            >{{
                                                                $t(
                                                                    "vouchers.tbl_hdr_amount"
                                                                )
                                                            }}</v-th
                                                        >
                                                    </v-tr>
                                                </v-thead>
                                                <v-tbody>
                                                    <v-tr
                                                        v-for="entr in hashed[
                                                            project_id
                                                        ][
                                                            m
                                                                .project_milestone_id
                                                        ]"
                                                    
                                                    >
                                                        <v-td>{{
                                                            entr.category_name
                                                        }}</v-td>
                                                        <v-td class="text-right"
                                                            >{{ entr.amount }}
                                                            {{
                                                                entr.currency
                                                            }}</v-td
                                                        >
                                                    </v-tr>
                                                    <v-tr v-if="false">
                                                        <v-td>SAMLET</v-td>
                                                        <v-td>{{ m }}</v-td>
                                                        <v-td>
                                                            {{
                                                                hashed[
                                                                    p.project_id
                                                                ]
                                                            }}
                                                        </v-td>
                                                    </v-tr>
                                                </v-tbody>
                                            </v-table>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </card>
                        </template>

                        <pre v-if="false">{{ meta }}</pre>
                    </template>
                    <template v-else
                        ><div class="alert alert-warning">
                            {{ $t("vouchers.alert_no_stat_data") }}
                        </div></template
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.table-voucher-report {
    width: 700px;

}




</style>
