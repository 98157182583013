var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row card-wrapper"},[_c('div',{staticClass:"col-12"},[(_vm.loading)?_c('v-loader',{attrs:{"loading":_vm.loading}}):[_c('card',{attrs:{"body-classes":"px-0"}},[_c('div',{staticClass:"d-flex align-items-center mb-4 justify-content-between px-4"},[_c('h2',{},[_vm._v(" "+_vm._s(_vm.$t("vouchers.cost_budget_report"))+" ")]),_c('cost-submenu',{attrs:{"project-id":_vm.project_id,"me":"costs"}})],1),_c('hr'),_c('div',{staticClass:"text-right mb-3 px-4"},[_c('a',{staticClass:"btn btn-primary btn-sm",attrs:{"href":("/v1/project-budgets/report/" + _vm.project_id + "?csv=1")}},[_c('i',{staticClass:"far fa-file-csv"}),_vm._v(" "+_vm._s(_vm.$t( "vouchers.download_budget_file" )))])]),_c('v-table',{staticClass:"table table-mg"},[_c('v-thead',[_c('v-tr',[_c('v-th',[_vm._v(_vm._s(_vm.$t( "project_budget.tbl_hdr_category_name" )))]),_c('v-th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t( "project_budget.tbl_hdr_booked" )))]),_c('v-th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t( "project_budget.tbl_hdr_budget" )))]),_c('v-th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t( "project_budget.tbl_hdr_balance" )))]),_c('v-th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t( "project_budget.tbl_hdr_balance_perc" )))])],1)],1),_c('v-tbody',_vm._l((_vm.entries),function(entry){return _c('v-tr',{key:entry.voucher_category_id,class:{
                                            'table-warning':
                                                entry.category_function == 2
                                        }},[_c('v-td',[_vm._v(_vm._s(entry.category_name))]),_c('v-td',{staticClass:"text-right"},[_c('hide-zero',{attrs:{"amount":entry.booked,"currency":entry.currency}})],1),_c('v-td',{staticClass:"text-right"},[(
                                                    entry.category_function ===
                                                        2
                                                )?[_vm._v("---")]:[_c('hide-zero',{attrs:{"amount":entry.budget,"currency":entry.currency}})]],2),_c('v-td',{staticClass:"text-right"},[(
                                                    entry.category_function ===
                                                        2
                                                )?[_vm._v("---")]:[_c('v-finance',{attrs:{"amount":entry.balance,"currency":entry.currency,"used":entry.booked,"budget":entry.budget}})]],2),_c('v-td',{staticClass:"text-right"},[(
                                                    entry.category_function ==
                                                        2
                                                )?[_vm._v("---")]:_c('v-balance',{attrs:{"balance":entry.balance_perc}})],2)],1)}),1)],1)],1)]],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }