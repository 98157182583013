<script>
/**
 * Detailed budget report
 */
import ProjectBudgetService from "@/services/ProjectBudgetService";
import CostSubmenu from "./components/CostSubmenu";
import VBalance from "@/components/Momentum/VBalance";
import { mapGetters } from "vuex";

export default {
    props: {
        project_id: {}
    },
    components: { CostSubmenu, VBalance },
    data() {
        return {
            loading: true,
            entries: [],
            header: [],
            oview: {},
            BK: false,
            showModal: false,
            selYear: "",
            selMon: ""
        };
    },
    computed: {
        ...mapGetters("auth", ["getAclMap"])
    },
    async created() {
        this.BK = this.$aclCan(this.$ACL.CUSTOM_PLANNING_BK);
        await this.fetchData();
        this.loading = false;
    },

    methods: {
        async fetchData() {
            try {
                const { data } = await ProjectBudgetService.GET_detailedReport(
                    this.project_id
                );
                this.entries = data.data.report;
                this.oview = data.data.overview;
                this.header = data.data.header;
                this.budgets = data.data.budgets;
            } catch (e) {
                console.log("fd e", e);
            }
        },
        showDetails(am, year, mon) {
            if (am == 0) {
                return;
            }

            this.selYear = year;
            this.selMon = mon;
            this.showModal = true;
        }
    }
};
</script>

<template>
    <div class="v-cost-detailed-budget-report">
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-loader :loading="loading" v-if="loading" />
                    <template v-else>
                        <card body-classes="px-0">
                            <div
                                class="d-flex align-items-center mb-4 justify-content-between px-4"
                            >
                                <h2 class="">
                                    {{
                                        $t(
                                            "vouchers.cost_detailed_budget_report"
                                        )
                                    }}
                                </h2>
                                <cost-submenu
                                    :project-id="project_id"
                                    me="costs"
                                />
                            </div>
                            <hr />

                            <div class="text-right mb-3 px-4">
                                <a
                                    :href="
                                        `/v1/project-budgets/download-detailed-report/${project_id}`
                                    "
                                    class="btn btn-primary btn-sm"
                                    ><i class="far fa-file-csv"></i>
                                    {{
                                        $t(
                                            "vouchers.download_detailed_budget_file"
                                        )
                                    }}</a
                                >
                            </div>
                            <div
                                v-for="(data, year) in oview"
                                class="px-4 overview"
                            >
                                {{ year }}

                                <div class="v_year">
                                    <div
                                        class="v_month"
                                        :class="
                                            am > 0 ? 'v_month__clickable' : ''
                                        "
                                        @click="showDetails(am, year, mon)"
                                        v-for="(am, mon) in data"
                                    >
                                        <div class="v_mname">{{ mon }}</div>
                                        <hide-zero
                                            class="text-default"
                                            :amount="am"
                                            :currency="$CURRENCY"
                                        />
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <h3 class="px-4">
                                {{ $t("vouchers.current_budgets") }}
                            </h3>
                            <div class="col-md-6">
                                <v-table class="table table-mg">
                                    <v-thead>
                                        <v-tr>
                                            <v-th>{{
                                                $t("vouchers.category")
                                            }}</v-th>
                                            <v-th class="text-right">{{
                                                $t("vouchers.amount")
                                            }}</v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr v-for="b in budgets">
                                            <v-td>
                                                {{ b.name }}
                                            </v-td>
                                            <v-td class="text-right">
                                                {{
                                                    $n(b.budget_amount, {
                                                        style: "currency",
                                                        currency: b.currency,
                                                        currencyDisplay: "code"
                                                    })
                                                }}
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>

                            <div class="table-responsive-mg" v-if="false">
                                <v-table class="table table-mg table-sm">
                                    <v-thead>
                                        <v-tr>
                                            <v-th> </v-th>
                                            <v-th></v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            v-for="vc in entries"
                                            :key="vc.voucher_category_id"
                                        >
                                            <v-td>{{ vc }}</v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </div>
                        </card>
                    </template>
                </div>
            </div>
        </div>
        <portal to="modals">
            <modal
                v-if="showModal"
                size="lg"
                :show="true"
                @close="showModal = false"
                class="budget-modal"
            >
                <template slot="header">
                    {{ $t("vouchers.detailed_monthly_budget") }}
                </template>

                <template slot="default">
                    <v-table class="table table-mg">
                        <v-thead>
                            <v-tr>
                                <v-th>{{ $t("vouchers.category") }}</v-th>
                                <v-th class="text-right">{{
                                    $t("vouchers.amount")
                                }}</v-th>
                            </v-tr>
                        </v-thead>
                        <v-tbody>
                            <v-tr
                                v-for="cat in entries[this.selYear][
                                    this.selMon
                                ]"
                            >
                                <v-td>{{ cat.name }}</v-td>
                                <v-td class="text-right">{{
                                    $n(cat.amount, {
                                        style: "currency",
                                        currency: $CURRENCY,
                                        currencyDisplay: "code"
                                    })
                                }}</v-td>
                            </v-tr>
                        </v-tbody>
                    </v-table>
                </template>
            </modal>
        </portal>
    </div>
</template>

<style lang="scss">
.v-cost-detailed-budget-report {
    .v_year {
        display: flex;
        margin-bottom: 30px;
    }

    .v_month {
        font-size: 10px;
        text-align: right;
        border-top: 1px solid $gray-300;
        border-right: 1px solid $gray-300;
        border-bottom: 1px solid $gray-300;
        padding: 8px 4px 4px 8px;
        flex: 0 0 calc(100% / 12); /* Always 12 equal parts */
        min-width: calc(100% / 12);

        &:first-of-type {
            border-left: 1px solid $gray-300;
        }

        &__clickable:hover {
            background: $primary;
            cursor: pointer;
            * {
                color: white !important;
            }
        }

        border-radius: 6px;
    }

    .v_mname {
        text-align: left;
        color: $gray-600;
    }
}
</style>
