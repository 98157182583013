<script>
import InvoiceOverview from "@/components/Projects/InvoiceOverview";
import VFilters from "@/components/Search/VFilters";
import ProjectService from "@/services/ProjectService";
import CurrencyHumanizer from "@/util/MillionHumanizer";
import DepartmentService from "@/services/DepartmentService";
import AppCfg from "@/mixins/AppCfg";

export default {
    name: "VMyCases",
    mixins: [AppCfg],
    components: { InvoiceOverview, VFilters },
    data() {
        return {
            loading: false,
            projects: [],
            depts: [],
            filterViews: {
                actawait: this.$t("projects.filter_active_and_awaiting"),
                act: this.$t("projects.filter_active"),
                awaiting: this.$t("projects.filter_awaiting"),
                fin: this.$t("projects.filter_finished"),
                ccl: this.$t("projects.filter_cancelled"),
                all: this.$t("projects.filter_all"),
            },
        };
    },
    computed: {
        filterInvoiceSetups() {
            return this.appCfgRead('Projects.invoice_setup', true)
        }
    },
    async created() {
        this.initSearch();
        this.$apiEnableWatcher();
        await this.fetchDepartments();
        await this.fetchData();
    },
    methods: {
        initSearch() {
            this.apiFilterView = "actawait"; // Active and awaiting (1,5)
            this.apiSearchFilter = { search: "", invoice_setup: "" };
        },
        /**
         * Fetch projects
         */
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const r = await ProjectService.GET_myOverview(params);
                this.projects = r.data.data;
            } catch (e) {
                console.log("Project exception", e);
            }
            this.loading = false;
        },
        /**
         * Fetch departments dropdown (for filters)
         */
        async fetchDepartments() {
            try {
                const { data } = await DepartmentService.dropdown();
                this.depts = data.data;
            } catch (e) {
                console.log("f dep, ", e);
            }
        },

        humanize(number) {
            return CurrencyHumanizer(number, this.$currency);
        },
    },
};
</script>

<template>
    <div class="v-projects">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        :enable-filters="true"
                        :start-open="true"
                        v-model="apiSearchFilter.search"
                        :search="true"
                        :search-placeholder="$t('projects.placeholder_search')"
                        :filter-views="filterViews"
                        :filter-view="apiFilterView"
                        :filter-name="$t('projects.filter_grp_name')"
                        filter-icon="far fa-flag-alt"
                        @filter-view-change="$handleFilterView($event)"
                        @reset-search="initSearch"
                    >
                        <template slot="default">
                            <div class="col-lg-4 col-md-12">
                                <FormulateInput
                                    v-model="apiSearchFilter.invoice_setup"
                                    :placeholder="$t('form_elems.invoice_setup')"
                                    type="multiselect"
                                    :multiple="false"
                                    :options="filterInvoiceSetups"
                                />
                            </div>
                        </template>
                    </v-filters>
                </div>
                <div class="col-12">
                    <v-loader :loading="loading" v-if="loading" />
                    <card body-classes="px-0" v-else-if="projects.length">
                        <invoice-overview
                            :projects="projects"
                            :show-names="false"
                        />
                    </card>
                    <card body-classes="px-0" v-else>
                        <div class="pb-mg">
                            <div class="nice-info">
                                {{ $t("projects.no_projects") }}
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>


<style lang="scss">
.v-filters {
    @media (max-width: 767.98px) {
        &__container {
            &__right {
                & > span {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
</style>
