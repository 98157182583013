<script>
import {
    FlagStatus,
    FlagTypes,
    FlagTypesDetailed,
    FlagTypesIcons,
    FlagSubtypes
} from "@/constants/Planning.const";
import { t } from "element-ui/lib/locale";

export default {
    props: {
        flag: {}
    },
    computed: {
        flagSubtypes() {
            return FlagSubtypes;
        },
        flagStatus() {
            return FlagStatus;
        }
    }
};
</script>

<template>
    <div class="small v-flag-meta text-muted">
        <div>
        {{ $t("flag.status") }}: {{ flagStatus[flag.status] }}
        </div>
        <div v-if="flag.done_date">
            <i class="far fa-calendar"></i> {{ $d(new Date(flag.done_date), 'short') }}
        </div>
    </div>
</template>

<style lang="scss">
.v-flag-meta {
    margin-top: 5px; padding-top: 5px;
    border-top: 1px dotted $border-color;
    
}
</style>
