<script>
/**
 * Report: flagging
 */
import VProjectReportGroup from "./components/VProjectReportGroup";
import ProjectFlagService from "@/services/ProjectFlagService";
import dayjs from "dayjs";

export default {
    components: { VProjectReportGroup },
    data() {
        return {
            loading: true,
            projects: {},
            selectedFilter: "",
            dateFilter: ""
        };
    },
    computed: {
        filters() {
            return [
                {
                    filter: "",
                    name: this.$t("flagging.f_type_all")
                },
                {
                    filter: "5",
                    name: this.$t("flagging.f_type_confirmed")
                },
                {
                    filter: "1",
                    name: this.$t("flagging.f_type_awaiting")
                },
                {
                    filter: "2",
                    name: this.$t("flagging.f_type_denied")
                }
            ];
        }
    },
    async created() {
        this.selectedFilter = "1";
        this.dateFilter = dayjs()
            .subtract(30, "day")
            .format("YYYY-MM-DD");
        await this.fetchData();
        this.$watch("dateFilter", this.fetchData);
        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                let params = {};
                params["status"] = this.selectedFilter;
                params["milestone_status_change"] = this.dateFilter;
                const { data } = await ProjectFlagService.GET_flagReport(
                    params
                );
                this.projects = data.data;
            } catch (e) {}
        },
        showTimeline(project_id) {
            this.timelineContext = project_id;
            this.showEditTimeline = true;
        },
        updateFilter(filter) {
            this.selectedFilter = filter;
            this.fetchData();
        },
        downloadPDF() {
            const url = `/v1/project-flags/flag-report?pdf=1&status=${this.selectedFilter}&milestone_status_change=${this.dateFilter}`;
            fetch(url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Failed to fetch PDF");
                    }
                    return response.blob();
                })
                .then(blob => {
                    const link = document.createElement("a");
                    const now = dayjs().format("DD-MM-YYYY");
                    link.href = URL.createObjectURL(blob);
                    link.download = `projects-flag-report-${now}.pdf`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(link.href);
                })
                .catch(error => console.error(error));
        }
    }
};
</script>

<template>
    <div class="v-planning-report">
        <base-header class="pb-1" type="">
            <bread-bag></bread-bag>
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper" v-if="true">
                <div class="col-12">
                    <card>
                        <div
                            class="d-flex justify-content-start align-items-center"
                        >
                            <div>
                                <base-button
                                    v-for="(filter, idx) in filters"
                                    :key="idx"
                                    size="sm"
                                    :type="
                                        selectedFilter == filter.filter
                                            ? 'primary'
                                            : 'secondary'
                                    "
                                    @click="updateFilter(filter.filter)"
                                    >{{ filter.name }}</base-button
                                >
                            </div>
                            <div class="ml-5">
                                <FormulateInput
                                    type="date"
                                    v-model="dateFilter"
                                    :validation-name="
                                        $t(
                                            'project.flag_report_date_filter_name'
                                        )
                                    "
                                    :help="
                                        $t(
                                            'project.flag_report_date_filter_help_text'
                                        )
                                    "
                                />
                            </div>
                            <base-button
                                @click="downloadPDF"
                                class="ml-auto"
                                type="primary"
                                outline
                                ><i class="far fa-file-pdf"></i>
                                {{ $t("project.export_pdf") }}</base-button
                            >
                        </div>
                    </card>
                    <v-loader :loading="loading" v-if="loading" />
                    <template v-else>
                        <v-project-report-group
                            :title="$t('project.group_on_track')"
                            normal
                            :flags="projects.normal"
                        />
                        <v-project-report-group
                            alarm="orange"
                            :title="$t('project.group_at_risk')"
                            :flags="projects.orange"
                        />
                        <v-project-report-group
                            alarm="red"
                            :title="$t('project.group_realized_risk')"
                            :flags="projects.red"
                        />
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.v-planning-report {
    .form-group {
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
    }
}
</style>
