<script>
/**
 * Project view
 * Master element containing sub-routing for a given project
 */
import { mapGetters } from "vuex";
import VDueDateColor from "@/components/Task/VDueDateColor";
import VInfoCard from "@/components/Cards/VInfoCard";
import CustomerService from "@/services/CustomerService";
import ProjectService from "@/services/ProjectService";
import NaviBlock from "@/components/Navigation/NaviBlock";
import ProjectMixin from "@/mixins/ProjectMixin";
import { EventBus } from "@/libraries/EventBus";

export default {
    name: "VProjectView",
    props: {
        project_id: {}
    },
    mixins: [ProjectMixin],
    components: { NaviBlock, VDueDateColor, VInfoCard },
    data() {
        return {
            loading: false,
            project: null,
            customer: null
        };
    },
    computed: {
        ...mapGetters("auth", ["getMyId", "getIsAdmin"]),
        /**
         * For admin cases - whether it's terminated or not
         */
        isTerminatedContract() {
            if (!this.project) {
                return false;
            }

            if (
                parseInt(this.project.project_model) === 3 &&
                this.project.contract.is_terminated === true
            ) {
                return true;
            } else {
                return false;
            }
        }
    },
    async created() {
        await this.fetchProject();
        EventBus.$on("project-refresh", this.fetchProject);
    },
    beforeDestroy() {
        EventBus.$off("project-refresh", this.fetchProject);
    },
    methods: {
        async fetchProject() {
            try {
                this.loading = true;
                const r = await ProjectService.GETS(this.project_id);
                this.project = r.data.data;
                this.customer = this.project.customer;
                await this.fetchCustomer(this.project.customer.id);
            } catch (e) {
                console.log("Faker", e);
            }
            this.loading = false;
        },
        async fetchCustomer(customer_id) {
            const r = await CustomerService.GETS(customer_id);
            this.customer = r.data.data;
        },
        navigateContracts() {
            this.$router.push({
                name: "CustomersTabContracts",
                params: { customer_id: this.project.customer_id }
            });
        }
    }
};
</script>

<template>
    <div>
        <v-loader :loading="loading" v-if="loading" />
        <template v-else-if="customer && project">
            <base-header class="pb-1" type="">
                <bread-bag
                    :crumbs="{
                        title: customer.name,
                        chain: [null, $t('breadcrumbs.details')]
                    }"
                >
                    <!-- Top-right menu -->
                    <div class="row">
                        <div class="col">
                            <navi-block
                                :navigate="{
                                    name: 'CustomersManage',
                                    params: { customer_id: customer.id }
                                }"
                                :title="$t('customers.top_menu_overview')"
                            />
                        </div>
                        <div class="col" v-if="customer.internal_customer == 1">
                            <navi-block
                                :navigate="{
                                    name: 'CustomersTabInternalTime',
                                    params: { customer_id: customer.id }
                                }"
                                :title="$t('customers.top_menu_internal_time')"
                            />
                        </div>
                        <div class="col">
                            <navi-block
                                :navigate="{
                                    name: 'CustomersTabCases',
                                    params: { customer_id: customer.id }
                                }"
                                :active="true"
                                :disable-route-matching="true"
                                :force-navigation="true"
                                :title="
                                    $tc(
                                        'customers.top_menu_cases',
                                        customer.count_cases
                                    )
                                "
                            />
                        </div>
                        <div class="col">
                            <navi-block
                                :navigate="{
                                    name: 'CustomersTabContracts',
                                    params: { customer_id: customer.id }
                                }"
                                :title="
                                    $tc(
                                        'contracts.top_menu_contracts',
                                        customer.count_contracts
                                    )
                                "
                            />
                        </div>
                        <div class="col">
                            <navi-block
                                :navigate="{
                                    name: 'CustomersTabInvoices',
                                    params: { customer_id: customer.id }
                                }"
                                :title="
                                    $t('customers.top_menu_invoice_archive')
                                "
                            />
                        </div>
                    </div>
                </bread-bag>
            </base-header>

            <!-- Here starts the below white-bar area -->
            <div class="container-fluid project-navigation" v-if="project">
                <div class="row">
                    <div
                        class="col-12 col-md-7 col-lg-6 project-navigation__title"
                    >
                        <h1
                            :class="{
                                'text-danger': isTerminatedContract
                            }"
                        >
                            <i
                                v-if="isTerminatedContract"
                                class="fa fa-exclamation-triangle"
                            ></i>
                            {{ project.title }}
                        </h1>

                        <div
                            class="text-danger mb-2"
                            v-if="isTerminatedContract"
                        >
                            {{
                                $t(
                                    "project.admin_case_terminated_contract_warning"
                                )
                            }}
                        </div>

                        <div class="project-navigation__metas">
                            <div class="project-navigation__metas--meta">
                                <i class="far fa-fw fa-hashtag"></i>
                                {{ project.id }}
                            </div>
                            <div class="project-navigation__metas--meta">
                                <i
                                    class="far fa-fw"
                                    :class="{
                                        'fa-wind-turbine':
                                            project.project_type == 1,
                                        'fa-solar-panel':
                                            project.project_type == 2
                                    }"
                                ></i>
                                {{ project.project_type_name }}
                            </div>

                            <div class="project-navigation__metas--meta">
                                <i class="far fa-fw fa-sack-dollar"></i>
                                {{ project.project_model_name }}
                            </div>

                            <div class="project-navigation__metas--meta">
                                <i class="far fa-fw fa-user"></i>
                                {{ project.responsible_user.name }}
                            </div>

                            <div class="project-navigation__metas--meta">
                                <v-due-date-color
                                    :context="project.deadline_date"
                                    ><i class="far fa-fw fa-calendar-star"></i
                                ></v-due-date-color>
                            </div>
                        </div>
                    </div>

                    <div
                        class="col-12 col-md-5 col-lg-6 project-navigation__static-meta"
                    >
                        <div class="info-boxes">
                            <v-info-card
                                v-if="project.budget_amount > 0"
                                :title="$t('project.budget')"
                            >
                                {{
                                    $n(project.budget_amount, {
                                        style: "currency",
                                        currency: project.currency,
                                        currencyDisplay: "code"
                                    })
                                }}</v-info-card
                            >
                            <v-info-card
                                class="m-click"
                                @click="navigateContracts"
                                v-if="project.project_model == 3"
                                :title="$t('project.contract')"
                            >
                                <span
                                    ><i class="far fa-file-signature"></i
                                ></span>
                            </v-info-card>
                            <v-info-card :title="$t('project.status')">
                                <span :class="statusColor">
                                    {{ project.status_name }}
                                </span>
                            </v-info-card>
                        </div>
                    </div>

                    <div class="col-12 project-navigation__submenu">
                        <div
                            class="row d-flex justify-content-between project-navigation__submenu--container"
                        >
                            <router-link
                                v-if="project.project_interface == 2"
                                class="col project-navigation__submenu--box"
                                :to="{ name: 'ProjectPlanningMilestones' }"
                                >{{ $t("project.planning") }}</router-link
                            >
                            <router-link
                                class="col project-navigation__submenu--box"
                                :to="{ name: 'ProjectViewTasks' }"
                                >{{ $t("project.activities") }}</router-link
                            >

                            <router-link
                                v-if="false"
                                class="col project-navigation__submenu--box"
                                :to="{ name: 'ProjectViewExpenses' }"
                                >{{ $t("project.expenses") }}</router-link
                            >

                            <router-link
                                class="col project-navigation__submenu--box"
                                :to="{ name: 'ProjectViewTransport' }"
                                >{{ $t("project.transport") }}</router-link
                            >

                            <router-link
                                v-if="
                                    customer.internal_customer != 1 &&
                                        project.project_model != 3 &&
                                        ($aclCan($ACL.TIMEREG_MGMT) ||
                                            project.user_id == getMyId)
                                "
                                class="col project-navigation__submenu--box"
                                :to="{ name: 'ProjectViewInvoicing' }"
                                >{{ $t("project.invoicing") }}</router-link
                            >

                            <router-link
                                v-if="project.project_model != 3"
                                class="col project-navigation__submenu--box"
                                :to="{ name: 'ProjectViewStats' }"
                                >{{ $t("project.statistics") }}</router-link
                            >

                            <router-link
                                v-if="project.project_model == 3"
                                class="col project-navigation__submenu--box"
                                :to="{ name: 'ProjectViewContractStats' }"
                                >{{ $t("project.statistics") }}</router-link
                            >

                            <router-link
                                v-if="
                                    project.enable_milestones === 1 &&
                                        project.project_model != 3 &&
                                        getIsAdmin
                                "
                                class="col project-navigation__submenu--box"
                                :to="{ name: 'ProjectViewMilestones' }"
                                >{{ $t("project.milestones") }}</router-link
                            >
                            <router-link
                                v-if="
                                    getIsAdmin && project.project_interface == 2
                                "
                                class="col project-navigation__submenu--box"
                                :to="{ name: 'ProjectViewCostsInbox' }"
                                >{{ $t("project.omk") }}</router-link
                            >

                            <router-link
                                class="col project-navigation__submenu--box"
                                :to="{ name: 'ProjectViewSettings' }"
                                >{{ $t("project.settings") }}</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>

            <router-view name="project_tab"></router-view>
        </template>
        <div v-else class="alert alert-warning">
            {{ $t("project.not_found") }}
        </div>
    </div>
</template>

<style></style>
