<script>
/**
 * Management of project milestones in a project (LOCAL CONTEXT)
 */
import ProjectService from "@/services/ProjectService";
import Cloner from "@/libraries/Cloner";
import VoucherService from "@/services/VoucherService";
import VMAllocations from "@/components/Costs/VMAllocations";
import VBookedVoucherModal from "@/components/Costs/VBookedVoucherModal";
import VAllocateModal from "@/components/Costs/VAllocateModal";
import VPdfModal from "@/components/Costs/VPdfModal";
import VFilters from "@/components/Search/VFilters";
import Messages from "@/mixins/Messages";
import CostSubmenu from "./components/CostSubmenu";

export default {
    name: "VCosts",
    props: {
        project_id: {}
    },
    mixins: [Messages],
    components: {
        VMAllocations,
        VBookedVoucherModal,
        VAllocateModal,
        VPdfModal,
        VFilters,
        CostSubmenu
    },
    data() {
        return {
            loading: true,
            vouchers: [],
            meta: {},
            vContext: null,
            allocateContext: null,
            showDetailsModal: false,
            showAllocateModal: false,
            project: {},
            showDocModal: false,
            docContext: null
        };
    },
    async created() {
        this.initSearch();
        await this.fetchProject();
        await this.fetchData();
        this.loading = false;
        this.$apiEnableWatcher();
    },
    methods: {
        async fetchData() {
            try {
                let params = this.$buildParams();
                params["project_id"] = this.project_id;
                params["functionView"] = "index";
                params["status"] = "10";
                const { data } = await VoucherService.GET(params);
                this.vouchers = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fd e", e);
            }
        },
        /**
         * Fetch the project + customer
         */
        async fetchProject() {
            try {
                const r = await ProjectService.GETS(this.project_id);
                this.project = r.data.data;
            } catch (e) {
                console.log("fetchProject exception", e);
            }
        },
        closeReload() {
            this.showAllocateModal = false;
            this.fetchData();
            this.$notify({
                message: this.$t("vouchers.notify_edit_entry_edited"),
                timeout: 5000,
                icon: "far fa-check",
                type: "success"
            });
        },
        /**
         * Show details in a modal`
         * @param ctx
         */
        showDetails(ctx) {
            this.vContext = ctx;
            this.showDetailsModal = true;
        },
        editAllocation(voucher) {
            this.allocateContext = voucher;
            this.showAllocateModal = true;
        },
        showVoucherDoc(v) {
            this.showDocModal = true;
            this.docContext = v;
        },
        reverseVoucher(voucher_id) {
            this.$askDeleteQuestion(
                this.$t("vouchers.swal_do_you_want_to_revert_the_voucher"),
                this.resetVoucher,
                voucher_id
            );
        },
        async resetVoucher(voucher_id) {
            try {
                await VoucherService.PUT_resetVoucher(voucher_id);
                this.$msgSuccess(this.$t("vouchers.notify_voucher_reset"));
            } catch (e) {
                this.$msgError(
                    this.$t("vouchers.notify_error_voucher_not_reset")
                );
                console.log(e);
            }

            this.fetchData();
        },
        initSearch() {
            this.apiSearchFilter = {
                search: ""
            };
        }
    }
};
</script>

<template>
    <div class="container-fluid">
        <div class="row card-wrapper">
            <div class="col-12">
                <v-filters
                    :enable-filters="false"
                    v-model="apiSearchFilter.search"
                    :search="true"
                    :search-placeholder="
                        $t('vouchers.placeholder_search_in_vouchers')
                    "
                    @reset-search="initSearch"
                />
                <card body-classes="">
                    <div
                        class="d-flex align-items-center mb-4 justify-content-between"
                    >
                        <h2 class="">{{ $t("vouchers.booked_entries") }}</h2>
                        <cost-submenu :project-id="project_id" me="costs" />
                    </div>

                    <v-loader :loading="loading" v-if="loading" />
                    <template v-else-if="vouchers.length > 0">
                        <v-table class="table table-mg table-sm">
                            <v-thead>
                                <v-tr>
                                    <v-th
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            name=""
                                            @click="handleSorting($event)"
                                            >{{
                                                $t("vouchers.hdr_tbl_date")
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            name=""
                                            @click="handleSorting($event)"
                                            >{{
                                                $t(
                                                    "vouchers.hdr_tbl_description"
                                                )
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th class="text-right"
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            name=""
                                            @click="handleSorting($event)"
                                            >{{
                                                $t("vouchers.hdr_tbl_amount")
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th class="text-center"
                                        ><v-sorter
                                            :paginator="apiPaginator"
                                            name=""
                                            @click="handleSorting($event)"
                                            >{{
                                                $t(
                                                    "vouchers.hdr_tbl_milestones"
                                                )
                                            }}</v-sorter
                                        ></v-th
                                    >
                                    <v-th></v-th>
                                </v-tr>
                            </v-thead>
                            <v-tbody>
                                <v-tr v-for="v in vouchers" :key="v.id">
                                    <v-td>{{
                                        $d(new Date(v.voucher_date), "short")
                                    }}</v-td>
                                    <v-td>{{ v.description }} </v-td>
                                    <v-td class="text-right">{{
                                        $n(v.amount, {
                                            style: "currency",
                                            currency: v.currency,
                                            currencyDisplay: "code"
                                        })
                                    }}</v-td>

                                    <v-td class="text-center"
                                        ><v-m-allocations
                                            :context="v.milestone_allocations"
                                    /></v-td>
                                    <v-td class="text-right">
                                        <base-button
                                            size="sm"
                                            outline
                                            type="danger"
                                            @click="reverseVoucher(v.id)"
                                        >
                                            <i
                                                class="far fa-trash fa-lg fa-fw m-click"
                                            ></i
                                        ></base-button>

                                        <base-button
                                            size="sm"
                                            outline
                                            type="primary"
                                            @click="showDetails(v)"
                                        >
                                            <i
                                                class="far fa-search-plus fa-lg fa-fw m-click"
                                            ></i
                                        ></base-button>

                                        <base-button
                                            size="sm"
                                            outline
                                            type="primary"
                                            @click="showVoucherDoc(v)"
                                        >
                                            <i
                                                class="far fa-file-pdf fa-lg fa-fw m-click"
                                            ></i
                                        ></base-button>

                                        <base-button
                                            size="sm"
                                            outline
                                            type="primary"
                                            @click="editAllocation(v)"
                                        >
                                            <i
                                                class="far fa-pencil fa-lg fa-fw m-click"
                                            ></i
                                        ></base-button>
                                    </v-td>
                                </v-tr>
                            </v-tbody>
                        </v-table>
                        <div class="paginator">
                            <base-pagination
                                :page-count="meta.pagination.total_pages"
                                :per-page="meta.pagination.per_page"
                                :value="meta.pagination.current_page"
                                :total="meta.pagination.total"
                                @input="$handlePagination($event)"
                            />
                        </div>

                        <pre v-if="false">{{ vouchers }}</pre>
                    </template>
                    <div class="alert alert-warning mx-4" v-else>
                        {{ $t("vouchers.no_vouchers_found") }}
                    </div>
                </card>
            </div>
        </div>
        <portal to="modals">
            <v-booked-voucher-modal
                :context="vContext"
                @close="showDetailsModal = false"
                v-if="showDetailsModal"
            />
            <v-allocate-modal
                :context="allocateContext"
                :project-id="project_id"
                :project="project"
                edit
                v-if="showAllocateModal"
                @close="showAllocateModal = false"
                @close-reload="closeReload"
            />
            <v-pdf-modal
                v-if="showDocModal"
                :context="docContext"
                @close="showDocModal = false"
            />
        </portal>
    </div>
</template>

<style lang="scss"></style>
