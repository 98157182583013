<script>
export default {
    props: {
        balance: {}
    },
    computed: {
        color() {
            if (this.balance < 80) {
                return "text-primary";
            } else if (this.balance >= 80 && this.balance <= 100) {
                return "text-warning";
            } else if (this.balance > 100) {
                return "text-danger";
            }
        }
    }
};
</script>

<template>
    <span
        ><i class="far fa-infinity text-danger" v-if="balance === 'inf'"></i>
        <template v-else
            ><span :class="color">{{ $n(balance) }}%</span></template
        ></span
    >
</template>
