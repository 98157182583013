<script>
import VListFlags from "./VListFlags";
import VStatusMilestone from "@/components/Planning/Helpers/VStatusMilestone";
import VFlagName from "@/components/Planning/VFlagName";
import VFlagMeta from "@/components/Planning/VFlagMeta";
import VTimelineDiff from "@/components/Planning/VTimelineDiff";
import VCod from "@/components/Planning/VCod";
import AppCfg from "@/mixins/AppCfg";
import MGConsts from "@/mixins/MGConsts";
import {
    FlagStatus,
    FlagTypes,
    FlagTypesDetailed,
    FlagTypesIcons,
    FlagSubtypes
} from "@/constants/Planning.const";
import VTimelineEditForm from "@/components/Forms/VTimelineEditForm";

export default {
    props: {
        flags: {},
        title: {},
        alarm: {
            type: String,
            default: "green"
        },
        normal: {
            type: Boolean,
            default: false
        }
    },
    mixins: [AppCfg, MGConsts],
    components: {
        VListFlags,
        VStatusMilestone,
        VTimelineEditForm,
        VFlagName,
        VFlagMeta,
        VTimelineDiff,
        VCod
    },
    data() {
        return {
            showEditTimeline: false,
            timelineContext: null,
            timelineFlagContext: null
        };
    },
    computed: {
        listFlags() {
            return FlagTypes;
        },
        flagTypes() {
            return this.$hash2select(FlagTypes);
        },
        flagDetails() {
            return FlagTypesDetailed;
        },
        flagIcons() {
            return FlagTypesIcons;
        },
        flagStatus() {
            return FlagStatus;
        },
        flagSubtypes() {
            return FlagSubtypes;
        }
    },
    methods: {
        showTimeline(project_id, flag_id) {
            this.timelineContext = project_id;
            this.timelineFlagContext = flag_id;
            this.showEditTimeline = true;
        }
    }
};
</script>

<template>
    <div class="v-project-report-group">
        <card>
            <h2 class="mb-3">
                <i class="fas fa-flag" :class="`flag-${alarm}`"></i> {{ title }}
            </h2>
            <template v-if="flags.length > 0">
                <v-table class="table table-sm table-bordered table__flag">
                    <v-thead>
                        <v-tr>
                            <v-th>{{ $t("flag_report.hdr_project") }}</v-th>
                            <v-th>{{ $t("flag_report.hdr_milestone") }}</v-th>
                            <v-th>{{ $t("flag_report.hdr_fsubtype") }}</v-th>
                            <template v-if="normal">
                                <v-th>{{ $t("flag_report.hdr_impact") }}</v-th>
                            </template>
                            <template v-else>
                                <v-th>{{
                                    $t("flag_report.hdr_timeline_change")
                                }}</v-th>
                                <v-th>{{
                                    $t("flag_report.hdr_cod_delay")
                                }}</v-th>
                                <v-th>{{ $t("flag_report.hdr_impact") }}</v-th>
                                <v-th>{{
                                    $t("flag_report.hdr_mitigation")
                                }}</v-th>
                            </template>
                        </v-tr>
                    </v-thead>
                    <v-tbody>
                        <v-tr v-for="(f, idx) in flags" :key="idx">
                            <!-- SECTION FOR PROJECTS ON TRACK -->
                            <template v-if="normal">
                                <v-td class="table__flag_title"
                                    ><router-link
                                        :to="{
                                            name: 'ProjectDisplay',
                                            params: { project_id: f.id }
                                        }"
                                        >{{ f.title }}</router-link
                                    ></v-td
                                >
                                <v-td class="text-center table__flag_milestone">
                                    <v-status-milestone
                                        v-if="
                                            Array.isArray(
                                                f.project_milestones
                                            ) && f.project_milestones.length > 0
                                        "
                                        :milestone="f.project_milestones[0]"
                                    />
                                    <template v-else>---</template>
                                </v-td>
                                <v-td class="table__flag_subtype">
                                    {{ $t("flag_report.milestone") }}
                                    {{ f.pm_data.m_ident }}
                                    <div class="small text-muted">
                                        {{ $t("flag_report.created") }}:
                                        {{
                                            $d(
                                                new Date(
                                                    f.pm_data.done_initiated_date
                                                ),
                                                "short"
                                            )
                                        }}
                                    </div>
                                    <div class="small v-flag-meta text-muted">
                                        <div>
                                            {{ $t("flag.status") }}:
                                            {{ f.pm_data.status_name }}
                                        </div>
                                        <div
                                            v-if="f.pm_data.done_confirmed_date"
                                        >
                                            <i class="far fa-calendar"></i>
                                            {{
                                                $d(
                                                    new Date(
                                                        f.pm_data.done_confirmed_date
                                                    ),
                                                    "short"
                                                )
                                            }}
                                        </div>
                                    </div>
                                </v-td>

                                <v-td class="table__flag_impact2"
                                    ><v-newlines :text="f.pm_data.done_note"
                                /></v-td>
                            </template>
                            <!-- SECTION FOR BROKEN PROJECTS -->
                            <template v-else>
                                <v-td class="table__flag_title"
                                    ><router-link
                                        :to="{
                                            name: 'ProjectDisplay',
                                            params: { project_id: f.project.id }
                                        }"
                                        >{{ f.project.title }}</router-link
                                    >
                                </v-td>
                                <v-td class="text-center table__flag_milestone"
                                    ><v-status-milestone
                                        v-if="
                                            Array.isArray(
                                                f.project.project_milestones
                                            ) &&
                                                f.project.project_milestones
                                                    .length > 0
                                        "
                                        :milestone="
                                            f.project.project_milestones[0]
                                        "
                                    />
                                    <template v-else>---</template></v-td
                                >
                                <v-td class="table__flag_subtype">
                                    <v-flag-name :flag="f" />

                                    <div
                                        class="small"
                                        v-if="f.subtype == 'timeline_change'"
                                    >
                                        <span
                                            class="m-click text-primary"
                                            @click="showTimeline(f.project_id)"
                                            ><i class="far fa-search"></i>
                                            {{
                                                $t("flag_report.show_details")
                                            }}</span
                                        >
                                    </div>
                                    <div class="small text-muted mt-2">
                                        {{ $t("flag_report.created") }}:
                                        {{ $d(new Date(f.created), "short") }}
                                    </div>
                                    <v-flag-meta :flag="f" />
                                </v-td>
                                <v-td class="table__flag_timeline_change"
                                    ><template v-if="f.diff_data"
                                        ><v-timeline-diff :diff="f.diff_data"
                                    /></template>
                                    <template v-else>---</template>
                                </v-td>
                                <v-td class="table__flag_cod">
                                    <template
                                        v-if="
                                            f.diff_data &&
                                                f.diff_data.new_cod_week
                                        "
                                    >
                                        <v-cod :diff-data="f.diff_data" />
                                    </template>
                                    <template v-else>---</template>
                                </v-td>

                                <v-td class="table__flag_impact"
                                    ><v-newlines :text="f.description"
                                /></v-td>
                                <v-td class="table__flag_how"
                                    ><v-newlines :text="f.description2"
                                /></v-td>
                            </template>
                        </v-tr>
                    </v-tbody>
                </v-table>
            </template>
            <div class="alert alert-outline alert-warning" v-else>
                {{ $t("project.group_no_projects_in_this_group") }}
            </div>
        </card>
        <portal to="modals">
            <v-timeline-edit-form
                v-if="showEditTimeline"
                :project-id="timelineContext"
                :project-flag-id="timelineFlagContext"
                :force-ro="true"
                @close="showEditTimeline = false"
            />
        </portal>
    </div>
</template>

<style lang="scss">
.v-project-report-group {
    .table__flag {
        td {
            text-wrap: wrap;
        }

        &_title {
        }
        &_type {
            width: 90px;
            text-align: center;
        }
        &_milestone {
            width: 60px;
        }
        &_subtype {
            width: 170px;
        }
        &_timeline_change {
            width: 170px;
        }
        &_cod {
            width: 170px;
        }
        &_category {
            width: 160px;
        }
        &_created {
            width: 120px;
        }
        &_impact {
            width: 340px;
        }

        &_impact2 {
            width: 1010px;
        }

        &_how {
            width: 340px;
        }
    }

    .flag {
        &-green {
            color: $success;
        }

        &-orange {
            color: $warning;
        }

        &-red {
            color: $danger;
        }
    }
}
</style>
