<script>
/**
 * OMK - project budgets (i.e. costs + budgets together in one table)
 */
import ProjectBudgetService from "@/services/ProjectBudgetService";
import CostSubmenu from "./components/CostSubmenu";
import VBalance from "@/components/Momentum/VBalance";
import { mapGetters } from "vuex";

export default {
    props: {
        project_id: {}
    },
    components: { CostSubmenu, VBalance },
    data() {
        return {
            loading: true,
            entries: [],
            BK: false
        };
    },
    computed: {
        ...mapGetters("auth", ["getAclMap"])
    },
    async created() {
        this.BK = this.$aclCan(this.$ACL.CUSTOM_PLANNING_BK);
        await this.fetchData();
        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                const { data } = await ProjectBudgetService.GET_report(
                    this.project_id
                );
                this.entries = data.data;
                if (!this.BK) {
                    this.entries.pop();
                }
            } catch (e) {
                console.log("fd e", e);
            }
        }
    }
};
</script>

<template>
    <div>
        <div>
            <div class="container-fluid">
                <div class="row card-wrapper">
                    <div class="col-12">
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else>
                            <card body-classes="px-0">
                                <div
                                    class="d-flex align-items-center mb-4 justify-content-between px-4"
                                >
                                    <h2 class="">
                                        {{ $t("vouchers.cost_budget_report") }}
                                    </h2>
                                    <cost-submenu
                                        :project-id="project_id"
                                        me="costs"
                                    />
                                </div>
                                <hr />

                                <div class="text-right mb-3 px-4">
                                <a
                                    :href="
                                        `/v1/project-budgets/report/${project_id}?csv=1`
                                    "
                                    class="btn btn-primary btn-sm"
                                    ><i class="far fa-file-csv"></i>
                                    {{
                                        $t(
                                            "vouchers.download_budget_file"
                                        )
                                    }}</a
                                >
                            </div>

                                <v-table class="table table-mg">
                                    <v-thead>
                                        <v-tr>
                                            <v-th>{{
                                                $t(
                                                    "project_budget.tbl_hdr_category_name"
                                                )
                                            }}</v-th>
                                            <v-th class="text-right">{{
                                                $t(
                                                    "project_budget.tbl_hdr_booked"
                                                )
                                            }}</v-th>
                                            <v-th class="text-right">{{
                                                $t(
                                                    "project_budget.tbl_hdr_budget"
                                                )
                                            }}</v-th>
                                            <v-th class="text-right">{{
                                                $t(
                                                    "project_budget.tbl_hdr_balance"
                                                )
                                            }}</v-th>
                                            <v-th class="text-right">{{
                                                $t(
                                                    "project_budget.tbl_hdr_balance_perc"
                                                )
                                            }}</v-th>
                                        </v-tr>
                                    </v-thead>
                                    <v-tbody>
                                        <v-tr
                                            :class="{
                                                'table-warning':
                                                    entry.category_function == 2
                                            }"
                                            v-for="entry in entries"
                                            :key="entry.voucher_category_id"
                                        >
                                            <v-td>{{
                                                entry.category_name
                                            }}</v-td>
                                            <v-td class="text-right">
                                                <hide-zero
                                                    :amount="entry.booked"
                                                    :currency="entry.currency"
                                                />
                                            </v-td>
                                            <v-td class="text-right"
                                                ><template
                                                    v-if="
                                                        entry.category_function ===
                                                            2
                                                    "
                                                    >---</template
                                                >
                                                <template v-else>
                                                    <hide-zero
                                                        :amount="entry.budget"
                                                        :currency="
                                                            entry.currency
                                                        "
                                                    /> </template
                                            ></v-td>
                                            <v-td class="text-right"
                                                ><template
                                                    v-if="
                                                        entry.category_function ===
                                                            2
                                                    "
                                                    >---</template
                                                >
                                                <template v-else>
                                                    <v-finance
                                                        :amount="entry.balance"
                                                        :currency="
                                                            entry.currency
                                                        "
                                                        :used="entry.booked"
                                                        :budget="entry.budget"
                                                    /> </template
                                            ></v-td>
                                            <v-td class="text-right">
                                                <template
                                                    v-if="
                                                        entry.category_function ==
                                                            2
                                                    "
                                                    >---</template
                                                >
                                                <v-balance
                                                    :balance="
                                                        entry.balance_perc
                                                    "
                                                    v-else
                                                />
                                            </v-td>
                                        </v-tr>
                                    </v-tbody>
                                </v-table>
                            </card>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
