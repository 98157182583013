<script>
export default {
    props: {
        milestone: {}
    }
};
</script>

<template>
    <div>
        <v-m :m="milestone" />
    </div>
</template>
