<script>
export default {
    props: {
        diff: {}
    }
};
</script>

<template>
    <div class="v-timeline-diff">
        <template v-if="'start_date' in diff">
            <div>{{ $t("flag_report.milestone") }} {{ diff.milestone }}</div>

            <div class="vtd_box">
                <div class="vtd_box__title">{{ $t("flag_report.start") }}:</div>
                <div class="vtd_box__data">
                    <template v-if="diff.start_date.from_week"
                        >{{ diff.start_date.from_week }}
                        {{ diff.start_date.from_week_year }} </template
                    ><template v-else
                        >({{ $t("flag_report.no_date") }})
                    </template>
                    <i class="fal fa-arrow-right"></i>
                    {{ diff.start_date.to_week }}
                    {{ diff.start_date.to_week_year }}
                </div>
            </div>

            <div class="vtd_box">
                <div class="vtd_box__title">{{ $t("flag_report.end") }}:</div>
                <div class="vtd_box__data">
                    <template v-if="diff.end_date.from_week"
                        >{{ diff.end_date.from_week }}
                        {{ diff.end_date.from_week_year }} </template
                    ><template v-else
                        >({{ $t("flag_report.no_date") }})
                    </template>
                    <i class="fal fa-arrow-right"></i>
                    {{ diff.end_date.to_week }} {{ diff.end_date.to_week_year }}
                </div>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
.v-timeline-diff {
    .vtd_box {
        // border: 1px solid red;
        padding-top: 8px;

        &__title {
            font-size: 11px;
            color: $gray-700;
        }

        &__data {
            font-size: 12px;
            color: $default;
            .fal {
                color: $primary;
            }
        }
    }
}
</style>
